import { useEffect, useMemo, useState } from 'react';
import { useScreenInfos } from './useScreenInfos';

type ReturnType = {
  showDetail: boolean;
  showMobileDetail: boolean;
  showList: boolean;
  setShowDetailForMobile: (value: boolean) => void;
  setUrlHash: (id: string) => void;
  viewType: PageViewType;
  setViewType: (value: PageViewType) => void;
  showForm: boolean;
  urlHash: string;
};

export type PageViewType = 'list' | 'detail' | 'edit' | 'create';

export const useListAndDetail = (): ReturnType => {
  const { isMobile, isDesktop } = useScreenInfos();

  const [showDetailForMobile, setShowDetailForMobile] = useState<boolean>(false);
  const [urlHash, setUrlHash] = useState<string>(window.location.hash);

  const [viewType, setViewType] = useState<PageViewType>('list');

  const showDetail = useMemo((): boolean => {
    if (isDesktop) return true;
    return showDetailForMobile;
  }, [isDesktop, showDetailForMobile]);

  const showList = useMemo((): boolean => {
    if (isDesktop) return true;
    return !showDetailForMobile;
  }, [isDesktop, showDetailForMobile]);

  const showMobileDetail = useMemo((): boolean => {
    return isMobile && showDetailForMobile;
  }, [isMobile, showDetailForMobile]);

  const showForm = useMemo(() => {
    return viewType === 'edit' || viewType === 'create';
  }, [viewType]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      setUrlHash(hash);
      if (hash) {
        setShowDetailForMobile(true);
        window.scrollTo({ top: 0, behavior: 'auto' });
      } else {
        setShowDetailForMobile(false);
      }
    };
    // hashの変更を監視する
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return {
    setShowDetailForMobile,
    showDetail,
    showMobileDetail,
    showList,
    setUrlHash: (id: string) => {
      if (id.length > 0) {
        window.location.hash = id;
      } else {
        // NOTE: It will remove empty hash from url
        history.replaceState('', document.title, window.location.pathname + window.location.search);
        // hashの削除はhashchangeイベントを発火しないので、手動で発火させる
        setUrlHash('');
        setShowDetailForMobile(false);
      }
    },
    viewType,
    setViewType,
    showForm,
    urlHash: urlHash.replace('#', ''),
  };
};
